import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import ProgramaIntegridade from './sections/programa-integridade/_index'
import CodigoCondutaEtica from './sections/codigo-conduta-etica/_index'
import CanalDeEtica from './sections/canal-de-etica/_index'
import NossasPoliticas from './sections/nossas-politicas/_index'
import ProcessoDeDenuncia from './sections/processo-de-denuncia/_index'
import RelacaoComFornecedores from './sections/relacao-com-fornecedores/_index'
import Anticorrupcao from './sections/anticorrupcao/_index'
import Legislacao from './sections/legislacao/_index'
import NossosPilares from './sections/nossos-pilares/_index'
import Blog from './sections/blog/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const Integridade = () => {
  const lang = 'pt'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <ProgramaIntegridade lang={lang} />
        <CodigoCondutaEtica lang={lang} />
        <CanalDeEtica lang={lang} />
        <NossasPoliticas lang={lang} />
        <ProcessoDeDenuncia lang={lang} />
        <RelacaoComFornecedores lang={lang} />
        <Anticorrupcao lang={lang} />
        <Legislacao lang={lang} />
        <NossosPilares lang={lang} />
        <Blog lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default Integridade
