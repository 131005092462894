import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'

import textPT from '../../assets/data/blog/textPT.json'
import textEN from '../../assets/data/blog/textEN.json'

import ImageWebp from 'src/components/ImageWebp'
import { BlogCarousel, ImgSize } from './style'

type BlogArticleType = {
  image: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}
interface ILang {
  lang: string;
}

const Blog = ({ lang }: ILang) => {
  const pageText = lang === 'pt' ? textPT : textEN
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-4 pb-md-5 pt-xl-0'>
      <div className='container pb-md-5 pb-lg-0'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h2 className='fs-24 fs-md-40 fs-lg-32 fs-xl-40 lh-30 lh-md-50 text-grayscale--500 text-center'>
              {pageText.title}
            </h2>
            <p className='fs-14 fs-md-18 fs-xl-24 lh-16 lh-md-22 lh-xl-30 text-grayscale--400 text-center mt-4'>
              {pageText.description}
            </p>
          </div>
          {
            pageText.blogContent.map((blogArticleContent: BlogArticleType, index: number) => (
              <BlogCarousel key={index} className='col-12 col-md-4 p-card mt-md-2 mb-5'>
                <article className='col-12 px-0'>
                  <ImgSize>
                    <ImageWebp
                      pathSrc={blogArticleContent.image}
                      altDescription={blogArticleContent.description}
                      arrayNumbers={[ 340, 216, 298, 363 ]}
                    />
                  </ImgSize>
                  <div className='col-12 pt-3 px-4 px-md-2 px-lg-4 mt-n5 bg-white content'>
                    <p className='fs-20 lh-25 fw-600 mt-2 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500 title'>{blogArticleContent.title}</p>
                    <p className='fs-14 fs-lg-16 lh-19 mb-2 fw-400 mt-3 text-grayscale--400'>{blogArticleContent.description}</p>
                    <a
                      data-blog={blogArticleContent.title}
                      href={blogArticleContent.link}
                      title={`Leia mais em ${blogArticleContent.link}`}
                      target='_blank'
                      rel='noreferrer'
                      className='fs-14 lh-17 fw-400 text-orange--extra text-right mt-xl-3'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_10',
                          section_name: pageText.title,
                          element_action: 'click button',
                          element_name: blogArticleContent.title,
                          redirect_url: blogArticleContent.link,
                        })
                      }}
                    >
                      {pageText.readMore}
                      <OrangeDsIcon size='MD' icon='arrow-right' color={orange.extra} />
                    </a>
                  </div>
                </article>
              </BlogCarousel>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Blog
